<template>
  <div>
    <v-divider />
    <v-data-table
      :search="search"
      :loading="loading"
      :headers="headers"
      :items="visits"
      sort-by="name"
      class="elevation-1 mt-3"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="بحث"
            single-line
            hide-details
          >
          </v-text-field>
        </v-toolbar>
        <v-dialog v-model="dialogDelete" max-width="300px">
          <v-card>
            <v-card-title class="headline">هل أنت متأكد من الحذف؟</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text-color="white"
                text
                @click="closeDelete"
                >إلغاء</v-btn
              >
              <v-btn
                color="gray"
                text-color="white"
                text
                @click="deleteItemConfirm"
                >موافق</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <template v-slot:[`item.task.name`]="{ item }">
        {{ item.task.name }}
      </template>
      <template v-slot:[`item.type`]="{ item }">
        {{
          item.typeId == 1
            ? "زيارة"
            : item.typeId == 2
            ? "تنبيه"
            : item.typeId == 3
            ? "طلب معلومات "
            : "-"
        }}
      </template>

      <template v-slot:[`item.infoSource`]="{ item }">
        {{ item.infoSource ? item.infoSource : "-" }}
      </template>
      <template v-slot:[`item.task.notes`]="{ item }">
        {{ item.task.notes ? item.task.notes : "-" }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon
          color="green"
          text-color="white"
          small
          class="mr-2"
          @click="
            $router.push({
              name: 'show-visit',
              params: { visit: item, visitId: item.idvisit }
            })
          "
        >
          mdi-eye
        </v-icon>
        <v-icon
          color="blue"
          v-if="role == 'Admin'"
          text-color="white"
          small
          class="mr-2"
          @click="
            $router.push({
              name: 'edit-task',
              params: { taskId: item.task.idtask }
            })
          "
        >
          mdi-pencil
        </v-icon>
        <v-icon
          color="red"
          v-if="role == 'Admin'"
          text-color="white"
          small
          @click="deleteItem(item.idvisit)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:no-data>
        لا توجد بيانات
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: ["visits"],
  data() {
    return {
      search: "",
      dialogDelete: false,
      loading: false,
      headers: [
        {
          text: "رقم الزيارة",
          align: "center",
          sortable: true,
          value: "idvisit"
        },
        {
          text: "اسم المهمة",
          align: "center",
          sortable: true,
          value: "task.name"
        },
        {
          text: "مصدر المعلومات",
          align: "center",
          sortable: true,
          value: "infoSource"
        },
        {
          text: "الملاحظات",
          align: "center",
          sortable: true,
          value: "task.notes"
        },
        { text: "خيارات", align: "center", value: "actions", sortable: false }
      ],
      dialog: false,
      page: 1,
      totalItems: 0,
      selectedExternalType: {
        value: 0
      },
      deletedItem: ""
    };
  },
  methods: {
    deleteItem(item) {
      this.deletedItem = item;
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      await this.ApiService.delete("Visit/DeleteVisit/" + this.deletedItem)
        .then(() => {
          this.hel.showSnackMsg("تمت العملية بنجاح", "#58dd5b", 2000);
          this.$emit("reload");
        })
        .catch(() => {
          this.hel.showSnackMsg("فشلت العملية", "#ff0000", 2000);
        });
      this.closeDelete();
    },
    closeDelete() {
      this.deletedItem = null;
      this.dialogDelete = false;
    },
    createdDate(date) {
      const current_datetime = new Date(date);
      return current_datetime.toLocaleDateString("en-US");
    }
  },
  created() {
    this.user = JSON.parse(window.localStorage.getItem("authUser"));
    this.role = window.localStorage.getItem("roles");
  }
};
</script>
