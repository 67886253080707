var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-divider'),_c('v-data-table',{staticClass:"elevation-1 mt-3",attrs:{"search":_vm.search,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.visits,"sort-by":"name"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"بحث","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-dialog',{attrs:{"max-width":"300px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("هل أنت متأكد من الحذف؟")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text-color":"white","text":""},on:{"click":_vm.closeDelete}},[_vm._v("إلغاء")]),_c('v-btn',{attrs:{"color":"gray","text-color":"white","text":""},on:{"click":_vm.deleteItemConfirm}},[_vm._v("موافق")]),_c('v-spacer')],1)],1)],1)]},proxy:true},{key:`item.task.name`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.task.name)+" ")]}},{key:`item.type`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.typeId == 1 ? "زيارة" : item.typeId == 2 ? "تنبيه" : item.typeId == 3 ? "طلب معلومات " : "-")+" ")]}},{key:`item.infoSource`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.infoSource ? item.infoSource : "-")+" ")]}},{key:`item.task.notes`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.task.notes ? item.task.notes : "-")+" ")]}},{key:`item.actions`,fn:function({ item }){return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"green","text-color":"white","small":""},on:{"click":function($event){return _vm.$router.push({
              name: 'show-visit',
              params: { visit: item, visitId: item.idvisit }
            })}}},[_vm._v(" mdi-eye ")]),(_vm.role == 'Admin')?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"blue","text-color":"white","small":""},on:{"click":function($event){return _vm.$router.push({
              name: 'edit-task',
              params: { taskId: item.task.idtask }
            })}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.role == 'Admin')?_c('v-icon',{attrs:{"color":"red","text-color":"white","small":""},on:{"click":function($event){return _vm.deleteItem(item.idvisit)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}},{key:"no-data",fn:function(){return [_vm._v(" لا توجد بيانات ")]},proxy:true}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }