<template>
  <div>
    <v-card>
      <v-tabs
        v-model="tab"
        fixed-tabs
        height="47"
        @change="getAllvisitsRequests"
      >
        <v-tab :href="'#tab-1'">
          <b>بانتظار المراجعة</b>
        </v-tab>
        <v-tab :href="'#tab-2'">
          <b>المؤكدة</b>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item :value="'tab-1'">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex>
                <visit-table @reload="getAllvisitsRequests" :visits="visits" />
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>
        <v-tab-item :value="'tab-2'">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex>
                <visit-table @reload="getAllvisitsRequests" :visits="visits" />
              </v-flex>
            </v-layout>
          </v-container>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import VisitTable from "./VisitTable.vue";

export default {
  name: "visit-reports",
  components: {
    "visit-table": VisitTable
  },
  data() {
    return {
      tab: null,
      text: "",
      visitReportTypes: [
        { value: "0", item: "اختر نوع تقرير الزيارة" },
        //  { value: '1', item: 'بانتظار المراجعة' },
        { value: "2", item: "قيد التقدم" },
        { value: "3", item: "منجزة" }
      ],
      selectedTaskType: "",
      visits: [],
      showAcceptButton: false,
      dialog: false,
      deleteVisit: 0,
      page: 1,
      totalItems: 0,
      user: null,
      role: null
    };
  },
  methods: {
    showDialog(id) {
      this.dialog = true;
      this.deleteVisit = id;
    },
    hideDialog() {
      this.dialog = false;
      this.deleteVisit = 0;
    },
    getAllvisitsRequests() {
      let url = "visit/WaitingReviewVisits?id=";
      if (this.tab == "tab-1") {
        url = "visit/WaitingReviewVisits?id=";
      }
      if (this.tab == "tab-2") {
        url = "visit/ArchiveVisit?id=";
      }
      this.visits = [];
      this.ApiService.get(url + this.user.id)
        .then(res => {
         
          this.visits = [];
          res.data.responseData.forEach(el => {
            if (el != null) {
              this.visits.push(el);
            }
          });
        })
        .catch(() => {});
    },
    deleteTask() {
      this.dialog = false;
      this.ApiService.delete("task/DeleteTask/" + this.deleteVisit)
        .then(() => {
          this.hel.showSnackMsg("تمت العملية بنجاح", "#58dd5b", 2000);
          this.getAllvisitsRequests();
          this.deleteVisit = 0;
        })
        .catch(() => {
          this.hel.showSnackMsg("فشلت العملية", "#ff0000", 2000);
        });
    },
    detailsTask(task) {
      this.$router.push({ name: "visit-details", params: task });
    }
  },
  created() {
    this.user = JSON.parse(window.localStorage.getItem("authUser"));
    this.role = window.localStorage.getItem("roles");
    //  this.getAllvisitsRequests();
  }
};
</script>
<style scoped>
b {
  font-size: 14px;
}
</style>
